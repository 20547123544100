@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
	* {
		transform: rotate(-90deg);
		transform-origin: left top;
		width: 100vh;
		overflow-x: hidden;
		position: absolute;
		top: 100%;
		left: 0;
	}
}

@font-face {
	font-family: 'SFMAC';
	font-style: normal;
	font-weight: normal;
	src: local('PC'),
		url('/src//font/sf-pro-display_regular.woff2') format('woff');
}

button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

* {
	font-family: 'SFMAC';
	font-style: normal;
	font-weight: lighter;
	text-shadow: 0.5px 0.5px 3px black;
	color: rgb(243, 243, 243);
}

RxCaretRight {
	fill: aquamarine !important;
	color: none !important;
}

a {
	text-decoration: none;
}
body {
	background-color: black;
	overflow-x: hidden;
	overflow-y: hidden;
}

@media (min-width: 641px) {
	.hand {
		position: absolute;
		background-size: cover;
		background-repeat: inherit;
		z-index: -10;
		right: -540px;
		transform: scale(0.99, 0.99);
	}
}

@media (max-width: 641px) {
	.hand {
		position: absolute;
		background-size: cover;
		background-repeat: inherit;
		z-index: -10;
		right: -540px;
		transform: scale(0.99, 0.99);
		opacity: 0;
	}
}

.App {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.Frame {
	position: relative;
	width: 361px;
	max-width: 361px;

	height: calc(var(--doc-height) - 20px);
	max-height: 690px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.phone {
	pointer-events: none;
	position: absolute;
	z-index: 15;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 109%;
	max-width: 361px;

	height: calc(var(--doc-height) - 20px);
	max-height: 690px;

	filter: brightness(80%);
}

.iphoneContent {
	position: relative;
	padding-top: 2%;
	width: 89%;
	height: 65%;
}

/* .wallpaper {
	position: absolute;
	padding-top: 2%;
	width: 100%;
	max-width: 330px;
	height: 65%;
	background: linear-gradient(
		32deg,
		rgb(24, 24, 24) 48%,
		rgba(168, 168, 168, 1) 100%
	);
	background: linear-gradient(
		32deg,
		rgb(24, 24, 24) 48%,
		rgba(168, 168, 168, 1) 100%
	);
	filter: brightness(75%);
} */

.powerBttn {
	position: absolute;
	z-index: 10;
	top: -0.5%;
	left: 75.2%;
	transform: translateX(-50%);

	opacity: 0;
}

.bttn {
	font-size: large;
	transform: scaleY(1.5);
	display: inline-block;
}

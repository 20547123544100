.ipod {
	height: 105%;
	max-height: 490px;
	margin-top: -3%;

	background-color: rgb(180, 180, 180);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.25),
		rgba(255, 255, 255, 0.25) 1px,
		transparent 1px,
		transparent 6px
	);
	background-size: 4px 4px;
}

Button:hover {
	background-color: rgb(255, 255, 255);
	opacity: 0.9;
}

.songsListed button {
	border: none;
}

.HEADER {
	position: absolute;
	color: rgb(248, 248, 248) !important;

	z-index: 1;
	font-weight: 500;
	opacity: 1;

	width: 100%;
	height: 100%;
	left: 40.5%;
	top: 0%;

	pointer-events: none;
}

@media screen and (max-height: 600px) {
	.HEADER {
		position: absolute;
		color: rgb(248, 248, 248) !important;

		z-index: 1;
		font-weight: 400;
		opacity: 1;

		font-size: 1.8rem;

		width: 100%;
		height: 100%;
		left: 41.5%;
		top: -1%;

		pointer-events: none;
	}
}

.filterBy {
	position: absolute;
	color: rgb(255, 255, 255);
	z-index: 2;
	letter-spacing: 0.03rem;
	font-size: 65%;
	font-weight: 150;
	opacity: 0.9;
	text-shadow: 1px 1px 85px black !important;

	left: 32.1%;
	top: 20.72%;
	pointer-events: none;
}

.filterBar {
	position: absolute;
	background-color: rgb(248, 248, 248);
	border-radius: 10px;
	z-index: 0;
	font-weight: 500;
	opacity: 0.95;
	box-shadow: 1.7px 1px 10px rgb(132, 132, 132);

	width: 40%;
	height: 3.5%;
	left: 30.5%;
	top: 20.5%;
}

.byDate {
	position: absolute;
	color: rgb(52, 52, 52);
	text-shadow: none;
	box-shadow: 1px 1px rgb(0, 0, 0);
	font-weight: bolder;
	background-color: rgb(54, 55, 56);
	text-align: center;
	border-radius: 10px;
	z-index: 1;
	font-weight: 500;
	opacity: 0.2;

	width: 24%;
	height: 3%;
	left: 30.5%;
	top: 20.7%;
	pointer-events: none;
}

.pausePlayOverlaySong1 {
	position: absolute;
	background-color: whitesmoke;
	opacity: 0.5;
	z-index: 10;
	border-radius: 9px;

	width: 100%;
	height: 32%;
	left: 0%;
	top: 0%;

	font-size: 170%;
}

.pausePlayOverlaySong2 {
	position: absolute;
	background-color: whitesmoke;
	opacity: 0.7;
	z-index: 10;
	border-radius: 9px;

	width: 100%;
	height: 32%;
	left: 0%;
	top: 34%;

	font-size: 170%;
}

.pausePlayOverlaySong3 {
	position: absolute;
	z-index: 7;
	opacity: 0.7;
	background-color: whitesmoke;

	text-align: center;
	align-items: center;
	color: rgb(255, 255, 255);
	letter-spacing: 0.182rem;

	height: 31.2%;
	width: 100%;
	left: 0%;

	top: 68.5%;
	border-radius: 9px;
}

.pausePlayIcon {
	position: absolute;
	z-index: 11;
	width: 20%;
	height: 75%;

	top: 14%;
	right: 74.7%;
}
@media (max-width: 641px) {
	.pausePlayIcon {
		position: absolute;
		z-index: 11;
		width: 20%;
		height: 75%;

		top: 14%;
		right: 72.7%;
	}
}

.songsListed {
	position: absolute;
	z-index: 2;
	display: flex;
	gap: 5px;
	flex-direction: column;

	width: 76%;
	top: 29%;
	left: 12%;
}

.songImg {
	width: 3.5rem;

	padding-top: 1.5%;
	padding-bottom: 1%;
	border-radius: 10%;
}

.songText {
	left: 10%;
}

.topBarIpod {
	position: absolute;
	z-index: 0;
	opacity: 0.9;

	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(100, 170, 208, 1) 68%
	);
	outline: 0.15rem solid rgb(106, 106, 106);

	width: 100%;
	height: 9%;

	top: 4.45%;
}

.bottomBar {
	position: absolute;
	z-index: 1;

	background-color: rgb(30, 30, 30);
	opacity: 0.9;
	width: 100%;
	height: 13%;

	top: 88%;
}

.farLeft {
	position: absolute;
	z-index: 1;

	width: 25%;
	height: 13%;
	left: 1%;
	top: 88%;
}

.musicIcon {
	position: absolute;
	z-index: 1;

	width: 40%;
	height: 100%;
	left: 30%;
}

.midLeft {
	position: absolute;
	z-index: 1;
	background-color: rgb(117, 117, 117);
	opacity: 0.7;
	width: 25%;
	height: 13%;
	left: 26%;
	top: 88%;
	border-radius: 10%;
}

.starIcon {
	position: absolute;
	z-index: 1;

	width: 45%;
	height: 100%;
	left: 29%;
	color: rgb(64, 240, 240) !important;
}

.starIconShadow {
	position: absolute;
	z-index: 0;

	width: 45%;
	height: 100%;
	left: 30.5%;
	top: 2%;
	color: rgb(1, 1, 1);
}

.midRight {
	position: absolute;
	z-index: 1;

	width: 25%;
	height: 13%;
	left: 51%;
	top: 88%;
}

.searchIcon {
	position: absolute;
	z-index: 1;

	width: 40%;
	height: 100%;
	left: 30%;
}

.farRight {
	position: absolute;
	z-index: 1;

	width: 25%;
	height: 13%;
	left: 76%;
	top: 88%;
}

.downloadIcon {
	position: absolute;
	z-index: 1;

	width: 40%;
	height: 100%;
	left: 23%;
}

/* SONG 1 */
.song1 {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	background-color: rgb(255, 255, 255);
	border-radius: 9px;
	overflow: hidden;
}

.playerFrameDopamine {
	position: absolute;
	z-index: 0;
	width: 130%;
	height: 59%;
	top: -13.65%;
	left: -2%;
	border: none;
	transform: scale(0.5);
	border-radius: 12px;
}

.songName1 {
	position: absolute;
	color: black;

	letter-spacing: 0.082rem;
	width: 55%;
	height: 25%;
	left: 35.8%;
	top: -2%;

	font-size: 170%;
}

.artistName1 {
	position: absolute;
	color: black;

	width: 55%;
	height: 25%;
	left: 35.8%;
	top: 15%;
	font-size: 80%;
}

/* SONG 2 */
.song2 {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	background-color: rgb(255, 255, 255);
	border-radius: 9px;
}

.playerFrameDenial {
	position: absolute;
	z-index: 0;
	width: 130%;
	height: 59%;
	top: 20.57%;
	left: -2%;
	border: none;
	transform: scale(0.5);
	border-radius: 12px;
}

.songName2 {
	position: absolute;
	color: black;

	letter-spacing: 0.082rem;
	width: 55%;
	height: 25%;
	left: 35.8%;
	top: 31%;

	font-size: 170%;
}

.artistName2 {
	position: absolute;
	color: black;

	width: 55%;
	height: 25%;
	left: 35.8%;
	top: 48%;

	font-size: 80%;
}

/* SONG 3 */
.song3 {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	background-color: rgb(255, 255, 255);
	border-radius: 9px;
}

.songName3 {
	position: absolute;
	color: black;

	width: 70%;
	height: 25%;
	left: 28.8%;
	top: 68%;

	font-size: 150%;
}

.artistName3 {
	position: absolute;
	color: black;
	text-align: center;

	width: 55%;
	height: 2%;
	left: 35.8%;
	top: 83%;

	font-size: 80%;
}

.explicit3 {
	position: absolute;
	color: black;
	text-align: center;
	opacity: 0.6;

	width: 6%;
	height: 6%;
	left: 88.8%;
	top: 89%;

	font-size: 80%;
}

.LouisBell {
	height: 105%;
	max-height: 490px;
	margin-top: -3%;

	background-color: rgb(247, 247, 247);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(174, 174, 174, 0.25),
		rgba(225, 225, 225, 0.25) 1px,
		transparent 1px,
		transparent 6px
	);
	background-size: 4px 4px;
}

.HEADERLouisBell {
	position: absolute;
	color: rgb(248, 248, 248) !important;
	z-index: 2;
	font-size: 2rem;
	font-weight: 500;
	opacity: 1;

	width: 100%;
	height: 100%;
	left: 29%;
	top: 0.2%;

	pointer-events: none;
}

@media screen and (max-height: 600px) {
	.HEADERLouisBell {
		position: absolute;
		color: rgb(248, 248, 248) !important;
		z-index: 2;
		font-size: 1.8rem;
		font-weight: 500;
		opacity: 1;

		width: 100%;
		height: 100%;
		left: 32.5%;
		top: -1%;

		pointer-events: none;
	}
}

.back {
	position: absolute;
	font-size: 2.2rem;
	z-index: 1;
	opacity: 1;

	width: 11%;
	height: 8%;

	top: 5.5% !important;
	left: 5% !important;
}

.textSongToLouisBubble {
	position: absolute;
	z-index: 1;
	background: rgb(203, 202, 202);
	opacity: 0.8;

	width: 50%;
	height: 17%;

	top: 28%;
	left: 8%;

	border-radius: 15px 15px 15px 4px;
}

.textToLouisBubble {
	position: absolute;
	z-index: 1;
	background: rgb(203, 202, 202);
	opacity: 0.8;

	width: 49%;
	height: 10%;

	top: 46%;
	left: 8%;

	border-radius: 15px 15px 15px 4px;
}

.textToLouis {
	position: absolute;
	z-index: 1;
	font-size: small;
	font-weight: 500;
	color: rgb(46, 46, 46);

	text-shadow: none;

	width: 42%;

	top: 47.2%;
	left: 13%;
	pointer-events: none;
}

.song002 {
	position: absolute;
	z-index: 3;
	opacity: 1 !important;

	width: 20%;
	height: 14%;

	left: 11%;
	top: 29.5%;
}

.songName002 {
	position: absolute;
	text-align: center;
	color: rgb(46, 46, 46);
	font-weight: 500;
	text-shadow: none;
	font-size: 0.9rem;
	z-index: 3;
	opacity: 1 !important;

	width: 20%;
	height: 14% !important;

	left: 34%;
	bottom: 54%;
}

.textSongToLouisBubbleInner {
	position: absolute;
	z-index: 2;
	background: rgb(255, 255, 255);
	opacity: 0.2;

	width: 48%;
	height: 15.5%;

	top: 28.75%;
	left: 9%;

	border-radius: 10px 10px 10px 10px;
}

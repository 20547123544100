#page-wrap {
	position: absolute;
	width: 82%;
	max-width: 720px;
	height: 130%;
	margin: 0 auto;
	padding-top: 120%;
	padding-left: 9.5%;
	z-index: 1;
}

@media (max-width: 641px) {
	#page-wrap {
		position: absolute;
		width: 82%;
		max-width: 720px;
		margin: 0 auto;
		padding-top: 112%;
		padding-left: 9%;
		z-index: 1;
	}
}

#well {
	padding: 0%;
	border-radius: 13px;
	background: -moz-linear-gradient(top, #010101, #181818);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0, #010101),
		color-stop(1, #181818)
	);
	border: 2px solid #454545;
	user-select: none;
}

.h2 {
	background: -moz-linear-gradient(
		left,
		#4d4d4d,
		0.4,
		#4d4d4d,
		0.5,
		white,
		0.6,
		#4d4d4d,
		#4d4d4d
	);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, #4d4d4d),
		color-stop(0.4, #4d4d4d),
		color-stop(0.5, white),
		color-stop(0.6, #4d4d4d),
		color-stop(1, #4d4d4d)
	);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	-moz-text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
	animation: slidetounlock 5s infinite;
	font-size: 1.5rem;
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
		Helvetica, Arial, 'Lucida Grande', sans-serif;
	font-weight: 300;
	/* padding: 0; */
	height: 100%;
	width: 150%;
	text-size-adjust: none;
}

#slider {
	background: url(./images/arrow.png) no-repeat;
	width: 20%;
	/* margin-top: 0.13%; */
	padding-top: 12%;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	background-size: contain;
}

.textInSlide {
	padding-left: 4%;
	color: transparent;
	background-image: linear-gradient(
		-250deg,
		#202020 0%,
		#6e6e6e 39%,
		#5a5a5a 75%,
		#aaaaaa 80%,
		#1d1d1d 100%
	);
	background-size: 200% 100%;
	-webkit-background-clip: text;
	animation: slide 5s linear infinite;
	white-space: nowrap; /* Prevent text from wrapping */
}

@keyframes slide {
	0% {
		background-position: 200% center;
	}
	100% {
		background-position: -200% center;
	}
}

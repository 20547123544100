.topBar {
	position: absolute;

	z-index: 1;
	width: 100%;
	height: 5%;
	top: -0.8%;
}

.lockIcon {
	padding-left: 48.75%;
	padding-top: 3%;
	font-size: 0.75rem;
}

@media (max-width: 641px) {
	.lockIcon {
		padding-left: 48.75%;
		padding-top: 3%;
		font-size: 0.6rem;
	}
}

.left {
	font-size: 0.7rem;
	position: absolute;
	z-index: 1;
	left: 5.5%;
	top: 42%;
	color: #d2d2d2;

	pointer-events: none;
}

.cellularBars {
	position: absolute;
	color: #d2d2d2;
	z-index: 1;
	height: 70%;
	left: 108.5%;
	top: 11.5%;
}

.clock {
	font-size: 0.7rem;
	position: absolute;
	justify-content: center;
	z-index: 1;
	text-align: center;
	left: 50.5%;
	top: 42%;
	transform: translateX(-50%);
	color: #f0f0f0;

	pointer-events: none;
}

.right {
	position: absolute;
	z-index: 1;

	top: 36%;
	left: 89.5%;

	opacity: 0.95;
}

/* ALT FOR WHEN NOT ON LOCK SCREEN */

.topBarAlt {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 5%;
	top: -0.8%;
	background-color: rgb(176, 176, 176);
	opacity: 80%;
}

.leftAlt {
	font-size: 0.7rem;
	position: absolute;
	z-index: 1;
	left: 5.5%;
	top: 42%;
	color: #282828;
	text-shadow: 0px 0px 0px rgb(157, 157, 157);

	pointer-events: none;
}

.cellularBarsAlt {
	position: absolute;
	color: #000000;
	z-index: 1;
	height: 70%;
	left: 108.5%;
	top: 11.5%;
}

.clockAlt {
	font-size: 0.7rem;
	position: absolute;
	display: flex;
	justify-content: center;
	z-index: 1;
	text-align: center;
	left: 50.5%;
	top: 42%;
	transform: translateX(-50%);
	color: #282828;
	text-shadow: 0px 0px 0px black;

	pointer-events: none;
}

.rightAlt {
	position: absolute;
	z-index: 1;

	top: 36%;
	left: 89.5%;

	opacity: 0.75;
}

.lockScreenPage {
	height: 105%;
	max-height: 490px;
	margin-top: -3%;

	background-color: rgb(0, 0, 0);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.25),
		rgba(255, 255, 255, 0.25) 1px,
		transparent 1px,
		transparent 6px
	);
	background-size: 4px 4px;
}

.lockScreenPicture {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
	width: 100%;
	max-width: 310px;

	top: 3.8%;
	left: 1.75%;
	right: 0%;

	height: 100%;
	max-height: 455px;

	margin-top: -4%;

	/* opacity: 8%; */
}

.homeScreenPicture {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
	width: 100%;
	max-width: 310px;

	left: 1.75%;
	right: 0%;

	height: 100%;
	max-height: 447px;

	margin-top: -3%;

	opacity: 90%;
	filter: brightness(80%);
}
/* POP UP */

.notificationAlert {
	position: absolute;
	background-color: rgba(59, 59, 59, 0.7);
	z-index: 12 !important;

	width: 83%;
	height: 12.5%;
	left: 8.7%;
	top: 60%;

	border-radius: 13px;
	/* box-shadow: 1.7px -2.5px 6px rgb(107, 107, 107); */
}

.notificationTextTop {
	position: absolute;
	color: rgb(182, 182, 182);
	font-size: 1.2rem;
	left: 35%;
	top: 34%;

	text-shadow: none !important;
}

.notificationTextBottem {
	position: absolute;
	color: rgb(91, 91, 91);

	font-size: 0.95rem;

	opacity: 0.9;

	left: 30%;
	top: 50%;

	text-shadow: none !important;
}

.notificationImg {
	position: absolute;
	color: black;

	height: 85%;

	left: 5%;
	top: 8%;

	border-radius: 5px;
	/* box-shadow: 1px 1px 5px rgb(129, 129, 129); */
}

.lockScreenTopTransSqr {
	position: absolute;
	z-index: 0.5;
	width: 100%;
	height: 22%;
	top: 4.2%;
	background: rgb(0, 0, 0);
	background: linear-gradient(0deg, rgb(32, 32, 32) 55%, rgb(57, 57, 57) 50%);
	border-bottom: 0.1px solid rgb(0, 0, 0);

	opacity: 65%;

	text-align: center;
}

.lockScreenClock {
	position: absolute;
	z-index: 10;
	font-size: 4rem;
	color: rgb(216, 216, 216);

	width: 100%;

	left: 0.5%;
	top: 4.2%;

	text-align: center;
}

.lockScreenDate {
	position: absolute;
	z-index: 10;
	font-size: 1.1rem;
	color: rgb(216, 216, 216);

	width: 100%;

	left: 0.5%;
	top: 19.5%;

	text-align: center;
}

.lockScreenBottomTransSqr {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 27%;
	top: 79%;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 60%,
		rgba(110, 110, 110, 1) 30%
	);
	border-top: 0.1px solid rgb(0, 0, 0);

	opacity: 65%;
}

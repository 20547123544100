.apps {
	display: grid;
	justify-content: center;
	text-align: center;
	align-items: center;
	position: absolute;
	grid-template-columns: 20% 20% 20% 20%;
	z-index: 10;

	width: 100%;
	max-width: 305px;
	left: 3.07%;
	height: 60%;

	margin-top: 5%;

	grid-column-gap: 0.7rem;
	place-items: center;

	filter: brightness(90%);
}

.dock {
	position: absolute;
	z-index: 0;
	justify-content: center;
	text-align: center;
	place-items: center;

	width: 100%;
	max-width: 310px;
	height: auto;
	bottom: -2%;
	left: 50%;
	transform: translateX(-50%);
	filter: brightness(104%);
}

.footerApps {
	display: grid;
	position: absolute;
	color: white !important;

	grid-template-columns: 20% 20% 20% 20%;
	width: 100%;
	max-width: 310px;
	height: 100%;
	max-height: -1px;

	z-index: 2;
	grid-column-gap: 0.7rem;

	justify-content: center;
	text-align: center;
	place-items: center;

	bottom: -50%;
	left: 50.5%;
	margin-bottom: 10%;

	transform: translateX(-50%);
	filter: brightness(90%);
}

.footerApps .appName {
	visibility: hidden;
}

.apps {
	box-shadow: none !important;
}

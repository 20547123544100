.homeBttn {
	position: absolute;
	z-index: 10;
	bottom: 7.5%;
	left: 50.2%;
	transform: translateX(-50%);
}

.bttn {
	font-size: x-large;
	transform: scaleY(2.5);
	display: inline-block;
}

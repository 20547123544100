.Apps {
	width: 100%;
	height: 100%;
	max-width: 59px;
	max-height: 76px;
}
.singleApp img {
	width: 100%;
	height: 100%;
	max-width: 59.19px;
	max-height: 58.19px;
}

.appName {
	font-size: 0.7rem;
	height: 13px;
	max-height: 13px;
}

.Apps:hover {
	opacity: 0.85;
	width: -20px !important;
	height: -20px !important;
	border-radius: 7px 7px;
}

.RickRubin {
	height: 105%;
	max-height: 490px;
	margin-top: -3%;

	background-color: rgb(247, 247, 247);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(174, 174, 174, 0.25),
		rgba(225, 225, 225, 0.25) 1px,
		transparent 1px,
		transparent 6px
	);
	background-size: 4px 4px;
}

.HEADERRickRubin {
	position: absolute;
	color: rgb(248, 248, 248);
	z-index: 1;
	font-size: 2rem;
	font-weight: 500;
	opacity: 1;

	width: 100%;
	height: 100%;
	left: 32%;
	top: 5%;

	pointer-events: none;
}

@media screen and (max-height: 600px) {
	.HEADERRickRubin {
		position: absolute;
		color: rgb(248, 248, 248);
		z-index: 1;
		font-size: 1.8rem;
		font-weight: 500;
		opacity: 1;

		width: 100%;
		height: 100%;
		left: 35%;
		top: 4.5%;

		pointer-events: none;
	}
}

.back {
	position: absolute;
	font-size: 2.2rem;
	z-index: 1;
	opacity: 1;

	width: 11%;
	height: 8%;

	top: 5.5% !important;
	left: 5% !important;
}

.caretPostion {
}

/* MY MESSAGES */

.textToRickBubble {
	position: absolute;
	z-index: 1;
	background: rgb(2, 154, 248);
	opacity: 0.8;

	width: 46%;
	height: 11%;

	top: 28%;
	left: 46%;

	border-radius: 15px 15px 4px 15px;
}

.textSongToRickBubble {
	position: absolute;
	z-index: 1;
	background: rgb(2, 154, 248);
	opacity: 0.8;

	width: 50%;
	height: 17%;

	top: 40%;
	left: 42%;

	border-radius: 15px 15px 4px 15px;
}

.textSongToRickBubbleInner {
	position: absolute;
	z-index: 2;
	background: rgb(202, 202, 202);
	opacity: 0.2;

	width: 48%;
	height: 15.5%;

	top: 40.75%;
	left: 43%;

	border-radius: 10px 10px 10px 10px;
}

.textToRick {
	position: absolute;
	z-index: 1;
	font-size: small;
	color: rgb(255, 255, 255);

	text-shadow: none;

	width: 42%;

	top: 30%;
	left: 49%;

	pointer-events: none;
}

.song001 {
	position: absolute;
	z-index: 3;
	opacity: 1 !important;

	width: 20%;
	height: 14%;

	left: 45%;
	top: 41.5%;
}

.songName001 {
	position: absolute;
	color: rgb(255, 255, 255);
	text-shadow: none;
	font-size: 0.68rem;
	z-index: 3;
	opacity: 1 !important;

	width: 20%;
	height: 14% !important;

	left: 69%;
	bottom: 42%;
}

/* RICK SIDE */

.rickTyping {
	position: absolute;

	top: 65%;
	left: 6%;

	width: 25%;
	height: 13%;
}

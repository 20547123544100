.game {
	position: absolute;
	z-index: 0;
	width: 100%;
	max-width: 400px;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgb(72, 72, 72);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(0, 0, 0, 0.25),
		rgba(0, 0, 0, 0.25) 1px,
		transparent 1px,
		transparent 6px
	);
	background-size: 4px 4px;
}

.gameWindow {
	width: 130%;
	height: 120%;
}

.loading {
	position: absolute;
	z-index: -1;
}

.cameraRollPage {
	width: 100%;
	max-width: 330px;
	overflow-y: auto;
	overflow: hidden;
	height: 105%;
	max-height: 490px;
	margin-top: -3%;

	background-color: rgb(0, 0, 0);

	background-size: 4px 4px;

	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.cameraRollPage::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.pictureViewer {
	position: absolute;
	z-index: 1000;
}

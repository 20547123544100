.weatherPage iframe {
	border: none;
	box-shadow: 5px 8px 30px 1px rgb(69, 69, 69);
}

.loading {
	position: absolute;
	z-index: -1;
}

.weatherPage {
	height: 105%;
	max-height: 490px;
	margin-top: -3%;

	background-color: rgb(180, 180, 180);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.25),
		rgba(255, 255, 255, 0.25) 1px,
		transparent 1px,
		transparent 6px
	);
	background-size: 4px 4px;
}

.spongebob {
	position: absolute;

	width: 55%;
	height: 25%;
	left: 25%;
	top: 19%;

	margin-left: -2%;

	pointer-events: none;
}

.warning {
	position: absolute;

	font-size: xx-large;
	color: rgb(21, 141, 221);
	text-shadow: 5rem rgb(21, 141, 221);

	width: 50%;
	height: 9%;
	left: 13.8%;
	top: 1%;

	pointer-events: none;
}

.heatwave {
	position: absolute;

	font-size: 3rem;
	font-weight: 400;
	color: rgb(21, 141, 221);
	text-shadow: 5rem rgb(21, 141, 221);

	width: 50%;
	height: 9%;
	left: 10%;
	top: 50%;

	pointer-events: none;
}

.weatherDate {
	position: absolute;

	font-size: 3.1rem;
	font-weight: 400;
	color: rgb(0, 0, 0);
	text-shadow: 5rem rgb(21, 141, 221);

	width: 100%;
	height: 9%;
	left: 22.4%;
	top: 67%;

	opacity: 0.8;
	pointer-events: none;
}

.barbottom {
	position: absolute;
	z-index: 0;
	background-color: black;
	opacity: 0.3;
	width: 40%;
	height: 1.8%;
	left: 7%;
	top: 90%;
}

/* .barbottomUp1 {
	position: absolute;
	z-index: 0;
	background-color: black;
	opacity: 0.8;
	width: 40%;
	height: 1.7%;
	left: 7%;
	top: 80%;
} */

.barbottomUp2 {
	position: absolute;
	z-index: 0;
	background-color: black;
	opacity: 0.3;
	width: 25%;
	height: 1.3%;
	left: 7%;
	top: 72%;

	box-shadow: 5px 2px 10px 1px rgb(69, 69, 69);
}

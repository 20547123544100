.messagesPage {
	height: 105%;
	max-height: 490px;
	margin-top: -3%;

	background-color: rgb(180, 180, 180);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.25),
		rgba(255, 255, 255, 0.25) 1px,
		transparent 1px,
		transparent 6px
	);
	background-size: 4px 4px;
}

.HEADERmessages {
	position: absolute;
	color: rgb(248, 248, 248) !important;
	background-color: none;

	z-index: 2;
	font-weight: 500;
	opacity: 1;

	width: 100%;
	height: 100%;
	left: 28.7%;
	top: -0.2%;

	pointer-events: none;
}

@media screen and (max-height: 600px) {
	.HEADERmessages {
		position: absolute;
		color: rgb(248, 248, 248) !important;
		background-color: none;

		font-size: 1.9rem;

		z-index: 2;
		font-weight: 500;
		opacity: 1;

		width: 100%;
		height: 100%;
		left: 30.7%;
		top: -2%;

		pointer-events: none;
	}
}

.messageCaret {
	position: absolute;
	z-index: 1;
	opacity: 1;
	color: #000000 !important;

	width: 20%;
	height: 40%;

	top: 35%;
	left: 80%;
}

.nameCircle {
	background-color: rgb(255, 255, 255);
	color: black;
	position: absolute;
	text-align: center;
	align-items: center;
	z-index: 3;

	left: 6.5%;
	top: 14%;

	width: 15%;
	height: 65%;

	border-radius: 100% 100% 100% 100%;
	box-shadow: 1.7px 1px 10px rgb(132, 132, 132);
}

.circleNameRR {
	position: absolute;
	color: #000000;
	font-size: 1.5rem;

	top: 20%;
	left: 18%;
}

.circleNameLB {
	position: absolute;
	color: #000000;
	font-size: 1.5rem;

	top: 20%;
	left: 35.5%;
}

.personsName {
	color: black;
	position: absolute;
	font-weight: bold;
	text-shadow: none !important;

	left: 25%;
	top: 8%;
}

.convo1Bar {
	position: absolute;
	z-index: 1;

	background-color: rgb(255, 255, 255);
	outline: 0.15rem solid rgb(106, 106, 106);

	opacity: 0.8;
	width: 100%;
	height: 16%;

	top: 13.9%;
}

.convo1Bar:hover {
	background-color: rgb(255, 255, 255);
	opacity: 0.92;
}

.rickTypingMessages {
	position: absolute;
	width: 15%;

	left: 25%;
	top: 30%;
}

.convo2Bar {
	position: absolute;
	z-index: 1;
	background-color: rgb(255, 255, 255);
	outline: 0.15rem solid rgb(106, 106, 106);

	opacity: 0.8;
	width: 100%;
	height: 16%;

	top: 30.2%;
}

.convo2Bar:hover {
	background-color: rgb(255, 255, 255);
	opacity: 0.99;
}

.louisTypingMessages {
	position: absolute;
	font-size: 0.9rem;
	color: #868686;
	text-shadow: none;

	width: 50%;

	left: 26%;
	top: 43%;
}

.RickConvo {
	position: absolute;
	top: 20%;
	left: 20%;
}

.LouisConvo {
	position: absolute;
	top: 50%;
	left: 50%;
}
